<template>
  <div class="col-12">
    <div class="account_item_head">
      <p class="account_item_head_text text--white">User Information</p>
    </div>
    <div class="row no-gutters account_item align-items-center">
      <div class="col-6">
        <span
          class="text--black text--opaq2 text--small text--bold account_item_title"
        >
          User Name
        </span>
        <span class="text--black text--regular account_item_subtitle overlap">
          {{ userInfo.username }}
        </span>
      </div>

      <div class="col-6">
        <span
          class="text--black text--opaq2 text--small text--bold account_item_title"
        >
          Assigned to Branch
        </span>
        <span class="text--black text--regular account_item_subtitle overlap">
          {{ branchName.branchName }}
        </span>
      </div>
    </div>

    <div class="row no-gutters account_item align-items-center">
      <div class="col-6">
        <span
          class="text--black text--opaq2 text--small text--bold account_item_title"
        >
          Role
        </span>
        <span class="text--black text--regular account_item_subtitle overlap">
          {{ userInfo.roleModel.roleName }}
        </span>
      </div>

      <div class="col-6">
        <span
          class="text--black text--opaq2 text--small text--bold account_item_title"
        >
          User State
        </span>
        <span class="text--black text--regular account_item_subtitle overlap">
          {{ userInfo.userStatus }}
        </span>
      </div>
    </div>

    <div class="row no-gutters account_item align-items-center">
      <div class="col-6">
        <span
          class="text--black text--opaq2 text--small text--bold account_item_title"
        >
          Email
        </span>
        <a
          class="text--black text--regular account_item_subtitle overlap cursor"
          style="color: #f58634 !important"
          :href="`mailto: ${userInfo.userEmail}`"
        >
          {{ userInfo.userEmail }}
        </a>
      </div>

      <div class="col-6">
        <span
          class="text--black text--opaq2 text--small text--bold account_item_title"
        >
          Language
        </span>
        <span class="text--black text--regular account_item_subtitle overlap">
          {{ userInfo.userLanguage }}
        </span>
      </div>
    </div>

    <div class="row no-gutters account_item align-items-center">
      <div class="col-6">
        <span
          class="text--black text--opaq2 text--small text--bold account_item_title"
          style="padding-right: 15px"
        >
          Can access clients and accounts data for all branches
        </span>
        <span class="text--black text--regular account_item_subtitle overlap">
          {{ userInfo.canaccessAllBranch ? "Yes" : "No" }}
        </span>
      </div>

      <div class="col-6">
        <span
          class="text--black text--opaq2 text--small text--bold account_item_title"
        >
          Access
        </span>
        <span class="text--black text--regular account_item_subtitle overlap">
          {{ userInfo.api_consumer_type }}
        </span>
      </div>
    </div>

    <div class="row no-gutters account_item align-items-center">
      <div class="col-6">
        <span
          class="text--black text--opaq2 text--small text--bold account_item_title"
        >
          Admin
        </span>
        <a class="text--black text--regular account_item_subtitle overlap">
          {{ userInfo.isAdministrator ? "Yes" : "No" }}
        </a>
      </div>

      <div class="col-6">
        <span
          class="text--black text--opaq2 text--small text--bold account_item_title"
        >
          Teller
        </span>
        <span class="text--black text--regular account_item_subtitle overlap">
          {{ userInfo.isTeller ? "Yes" : "No" }}
        </span>
      </div>

      <div class="col-6">
        <span
          class="text--black text--opaq2 text--small text--bold account_item_title"
        >
          Credit Officer
        </span>
        <span class="text--black text--regular account_item_subtitle overlap">
          {{ userInfo.isCreditOfficer ? "Yes" : "No" }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import ApiService from "@/core/services/general.service";

export default {
  props: {
    userInfo: [Array, Object],
  },

  data() {
    return {
      branchName: "",
    };
  },

  methods: {
    getBranchDetails() {
      this.branchName = "";
      ApiService.get(`Branch/${this.userInfo.branchKey}`).then((resp) => {
        this.branchName = resp.data.data;
      });
    },
  },

  mounted() {
    this.getBranchDetails();
  },

  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
};
</script>
